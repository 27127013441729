a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid #00000000;
  border-radius: 8px;
  background: #ccc;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:active {
  background: #aaa;
  background-clip: padding-box;
}

#login {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
}

.MuiAppBar-root-17 {
  position: absolute;
  z-index: -1;
}

.MuiCollapse-container-170 {
  width: 100%;
}

.MuiStepIcon-active {
  border-radius: 12px;
  box-shadow: 0 0 0 0 #f44232;
  animation: pulse 1s infinite;
}

.auth0-lock-content-wrapper {
  flex-grow: 0 !important;
}

[id*="react-select"] {
  opacity: 1 !important;
}

@keyframes pulse {
  0% {
    filter: brightness(80%);
  }
  45% {
    filter: brightness(100%);
  }
  80% {
    box-shadow: 0 0 0 8px #00000000;
  }
  100% {
    filter: brightness(80%);
    box-shadow: 0 0 0 0 #00000000;
  }
}